<template>
  <v-card class="pa-1 rounded-lg">
    <v-card-text class="white">
      <v-card class="mb-7" color="comment" rounded="lg" outlined flat>
        <v-card-title>
          <v-spacer></v-spacer>

          <v-btn
            v-if="$store.getters['rolesUser/getCustomRole'](186)"
            @click="saveProvidersAllocation"
            color="save"
            dark
            small
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>

      <AccountingAssign
        v-if="$store.getters['rolesUser/getCustomRole'](184)"
        :form="form"
        :win="win"
        :assignAll="true"
      >
        <template>
          <v-btn
            color="primary"
            @click="assignAllAccounts"
            :disabled="!form.id_account"
          >
            Asignar Todas
          </v-btn>
        </template>
      </AccountingAssign>

      <VersatileFilter
        entity="lista"
        :filterEndpoint="$API.providers.getProvidersFilteredWithAccounts"
        :search="search"
        @emptyFilter="getAllProviders"
        @filtered="filterHandlerProviders"
        :columnfilterEndpoint="
          $API.providers.getProvidersFilteredColumnWithAccounts
        "
        :columnsSearch="columsConfig"
        :disableDates="true"
        v-if="$store.getters['rolesUser/getCustomRole'](178)"
      >
        <DxDataGrid
          ref="accountProviders"
          :data-source="providers"
          key-expr="ID_Provider"
          :show-row-lines="showRowLines"
          @exporting="onExporting"
          :show-borders="showBorders"
          :row-alternation-enabled="rowAlternationEnabled"
          @editing-start="onEditingStart"
          :scrolling="{ useNative: true }"
          :column-auto-width="true"
          @row-updating="validateData"
        >
          <DxEditing :allow-updating="true" mode="cell" />
          <DxScrolling row-rendering-mode="virtual" />
          <DxExport :enabled="$store.getters['rolesUser/getCustomRole'](187)" />
          <DxPaging :page-size="10" />
          <DxColumn
            data-field="ID_Provider"
            caption="ID"
            :allow-updating="false"
          ></DxColumn>
          <DxColumn
            data-field="ProviderName"
            caption="Nombre del Proveedor"
            :allow-updating="false"
          ></DxColumn>
          <DxColumn
            data-field="ProviderContactName"
            caption="Contacto del Proveedor"
            :allow-updating="false"
          ></DxColumn>
          <DxColumn
            data-field="ProviderContactMobile"
            caption="Celular de Contacto del Proveedor"
            :allow-updating="false"
          ></DxColumn>
          <DxColumn
            data-field="account_code"
            :allow-updating="true"
            caption="Cuenta de compras"
          ></DxColumn>

          <template #show-template="{ data }">
            <v-btn
              class="mx-1"
              @click="
                servicesSelected = data;
                showModel = true;
              "
              x-small
              icon
            >
              <v-icon>mdi-magnify </v-icon>
            </v-btn>
          </template>
        </DxDataGrid>
      </VersatileFilter>

      <v-dialog v-model="showModel" width="60%">
        <v-card>
          <AccountingItemForm
            :win="win"
            v-on:sendSelection="reaciveSelection"
          />
        </v-card>
      </v-dialog>

      <alert
        v-model="alert.show"
        :type="alert.type"
        :title="alert.header"
        :message="alert.body"
      ></alert>
    </v-card-text>
  </v-card>
</template>

<script>
import VersatileFilter from "@/components/VersatileFilter";
import AccountingItemForm from "./AccountingItemForm";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxExport,
  DxEditing,
} from "devextreme-vue/data-grid";
import AccountingAssign from "./AccountingAssign.vue";
import { mapMutations, mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
export default {
  name: "AllocationServices",
  props: ["win", "datafilter"],
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxScrolling,
    DxPaging,
    AccountingItemForm,
    DxExport,
    VersatileFilter,
    AccountingAssign,
  },

  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      tab: null,
      panel: 0,
      form: {},
      servicesSelected: false,
      providers: {},

      search: {
        filter: null,
      },
      valid: null,
      editService: null,
      showModel: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      typebusiness: null,
      columsConfig: [
        {
          name: "ip.ID_Provider",
          title: "ID",
          value: "",
          type: "text",
        },
        {
          name: "ip.ProviderName",
          title: "Nombre del proveedor",
          value: "",
          type: "text",
        },
        {
          name: "ip.ProviderContactName",
          title: "Contacto del proveedor",
          value: "",
          type: "text",
        },
        {
          name: "ip.ProviderContactMobile",
          title: "Celular de Contacto del Proveedor",
          value: "",
          type: "text",
        },
      ],

      filterServices: {},
      showOrderVacMed: false,
      optFilterOrder: 1,
      families: [],
      categories: [],
      subcategories: [],
      accountList: [],
    };
  },

  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    loading: {
      ...mapState({ get: "loading" }),
      ...mapMutations({ set: "loading" }),
    },
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    this.typebusiness =
      JSON.parse(localStorage.getItem("user")).businesstype || "";
    this.filterServices.business = JSON.parse(
      localStorage.getItem("user")
    ).businessid;
    this.filterServices.branch = JSON.parse(
      localStorage.getItem("user")
    ).branch;
    this.search.branch = this.filterServices.branch;
    this.search.business = this.filterServices.business;
    this.getAllProviders();
    this.getFamilies();
    this.getCategories();
    this.getSubs();
  },
  methods: {
    reaciveSelection(data) {
      this.showModel = false;
      this.servicesSelected.data.newId_account = data.id_account;
      this.servicesSelected.data.account_code = data.account_code;
      this.servicesSelected.data.newAccount_Code = data.account_code;
      this.$refs.accountProviders.instance.saveEditData();
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then((response) => {
        this.families = response;
        this.families.forEach((family) => {
          this.columsConfig[4].options.push({
            name: family.name,
            value: family.id,
          });
        });
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategories().then((response) => {
        this.categories = response;
        this.categories.forEach((category) => {
          this.columsConfig[5].options.push({
            name: category.name,
            value: category.id,
          });
        });
      });
    },
    validateData(e) {
      console.log(e);
      if (e.newData.account_code) {
        const found = this.accountList.find(
          (current) => current.account_code === e.newData.account_code
        );

        if (found) {
          e.newData.newId_account = found.id_account;
          e.newData.account_code = found.account_code;
        } else {
          e.cancel = true;
          this.showAlert("warning", "Error", "La cuenta ingresada no existe");
        }
      }
    },
    getSubs() {
      this.$API.itemtypes.getSubs().then((response) => {
        this.subcategories = response;
        this.subcategories.forEach((subcategory) => {
          this.columsConfig[6].options.push({
            name: subcategory.name,
            value: subcategory.id,
          });
        });
      });
    },
    assignAllAccounts() {
      for (const index in this.providers) {
        const found = this.accountList.find(
          (current) => current.id_account === this.form.id_account
        );

        if (found) {
          this.providers[index].newId_account = found.id_account;
          this.providers[index].account_code = found.account_code;
        }
      }
      this.$refs.accountProviders.instance.refresh();
      this.showAlert(
        "success",
        "Exito",
        'Se ha actualizado la tabla asignando la cuenta seleccionada a los proveedores. Revise la columna "Cuenta de Ventas a Asociar" para verificar y haga click el boton verde para guardar'
      );
    },
    getAllAccounts(dataToFormat) {
      this.$API.branchaccountcatalog
        .getAssignableAccounts()
        .then((response) => {
          this.accountList = response.data;
          this.matchAccounts(dataToFormat);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    matchAccounts(dataToFormat) {
      for (const index in dataToFormat) {
        const { id_sales_account } = dataToFormat[index];

        const found = this.accountList.find(
          (current) => current.id_account === id_sales_account
        );

        if (found) {
          dataToFormat[index].account_code = found.account_code;
        }
      }

      this.$refs.accountProviders.instance.saveEditData();
    },

    saveProvidersAllocation() {
      try {
        this.loading = true;
        this.$refs.accountProviders.instance.refresh();
        this.$API.branchaccountcatalog
          .assignAccountsProviders([...this.providers])
          .then((response) => {
            this.showAlert("success", "Exito", response.msg);
            this.loading = false;
            this.getAllProviders();
          })
          .catch((error) => {
            this.showAlert("error", "Error", error.message);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    onExporting(e) {
      printToExcel(e, "Proveedores");
    },
    reload() {
      this.getAllProviders();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
      }, 300);
    },
    showAlert(type, header, body) {
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
    },
    getAllProviders() {
      this.filterServices.withInnerjoins = true;
      this.$API.providers
        .getProvidersAllWithAccounts(this.filterServices)
        .then(async (response) => {
          await this.getAllAccounts(response);
          this.providers = response;
        });
    },
    onEditingStart(e) {
      if (e.column.dataField != "account_code") {
        e.cancel = true;
      }
    },

    filterHandlerProviders(response) {
      this.providers = response;
    },
  },
};
</script>
