<template>
  <div>
    <block v-if="loading" />

    <v-card flat class="pa-4 bar mb-7">
      <div class="d-flex justify-end align-center">
        <v-btn
          color="save"
          dark
          class="mr-1"
          small
          @click="saveProductsAllocation"
          v-if="$store.getters['rolesUser/getCustomRole'](181)"
        >
          <v-icon> mdi-content-save </v-icon>
        </v-btn>
      </div>
    </v-card>

    <AccountingAssign
      v-if="$store.getters['rolesUser/getCustomRole'](179)"
      :form="form"
      :win="win"
      :assignAll="true"
    >
      <template>
        <v-btn
          v-if="$store.getters['rolesUser/getCustomRole'](184)"
          color="primary"
          dark
          @click="assignAllAccounts"
          :disabled="!form.id_account"
        >
          Asignar Todas
        </v-btn>
      </template>
    </AccountingAssign>
    <v-col cols="12">
        <v-radio-group v-if="$store.getters['rolesUser/getArrayCustomRole']([184, 183, 180])" v-model="changueAll" row >
         
            <v-radio  v-if="$store.getters['rolesUser/getArrayCustomRole']([184, 183])"   label="Asignar Cuentas de Inventarios" value="inventory"></v-radio>
            <v-radio  v-if="$store.getters['rolesUser/getArrayCustomRole']([184, 180])" label="Asignar Cuentas de Costo Venta" value="sales"></v-radio>
            <v-radio  v-if="$store.getters['rolesUser/getArrayCustomRole']([184, 180])" label="Asignar Cuentas de Ventas" value="taxed_sales"></v-radio>
        </v-radio-group>
      </v-col>
    
    <div >
      
      <VersatileFilter 
        v-if="$store.getters['rolesUser/getCustomRole'](177)"
        @emptyFilter="getAllProducts" 
        @filtered="filterHandler"
        :columnsSearch="columsConfig" 
        :filterEndpoint="$API.products.filterProducts" 
        :search="search" 
        :key="Reloadfilter" 
        :columnfilterEndpoint="$API.products.filterProductsColumn" 
        :disableDates="true" 
      >
        <DxDataGrid
          ref="accountProducts"
          :data-source="products"
          key-expr="ID_Item"
          :show-row-lines="showRowLines"
          @exporting="onExporting"
          :show-borders="showBorders"
          :row-alternation-enabled="rowAlternationEnabled"
          @editing-start="onEditingStart"
          :scrolling="{ useNative: true }"
          :column-auto-width="true"
          @row-updating="validateData"
          style="width: auto;"
        >
          <DxExport :enabled="$store.getters['rolesUser/getCustomRole'](182)" />
          <DxEditing :allow-updating="true" mode="cell" />
          <DxPaging :page-size="10" />
          <DxColumn :allow-updating="false"  data-field="ID_Item" caption="ID Producto"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="ItemType" caption="Tipo de Producto"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="ItemName" caption="Nombre del Producto"></DxColumn>
          
         
          <DxColumn :allow-updating="false"  data-field="stock" caption="Disponible"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="WarehouseName" caption="Almacenes"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="FamilyName" caption="Familia"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="CategoryName" caption="Categoría"></DxColumn>
          <DxColumn :allow-updating="false"  data-field="SubCatName" caption="Subcategoría"></DxColumn>
          
          <DxColumn :width="80" v-if="$store.getters['rolesUser/getCustomRole'](183)" cell-template="show-template" caption=""></DxColumn>
          <DxColumn data-field="inventory_account_code" :allow-updating="true" caption="Cuenta de inventarios"></DxColumn>
          <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](180)" :width="80" cell-template="show-template_2" caption=""></DxColumn>
          <DxColumn data-field="sales_account_code" :allow-updating="true" caption="Cuenta de Costo de Venta"></DxColumn>
          <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](180)" :width="80" cell-template="show-template_3" caption=""></DxColumn>
          <DxColumn data-field="taxed_sales_account_code" :allow-updating="true" caption="Cuenta de Ventas"></DxColumn>

          <template #show-template="{ data }">
            <div>
              <a href="#" class="mx-1" @click="showProduct = data; showModel =true; assignOne = 'inventory'">
                <v-icon>mdi-magnify

                </v-icon>
              </a>
            </div>
          </template>
          <template #show-template_2="{ data }">
            <div>
              <a href="#" class="mx-1" @click="showProduct = data; showModel =true; assignOne = 'sales'">
                <v-icon>mdi-magnify

                </v-icon>
              </a>
            </div>
          </template>
          <template #show-template_3="{data}">
            <div>
              <a href="#" class="mx-1" @click="showProduct = data; showModel =true; assignOne = 'taxed_sales'">
                <v-icon>mdi-magnify

                </v-icon>
              </a>
            </div>
          </template>
          <template #setFamilies="{ data }">
            <div>
              <span>
                {{ setFamilies(data.data) }}
              </span>
            </div>
          </template>
          <template #setCategories="{ data }">
            <div>
              <span>
                {{ setCategories(data.data) }}
              </span>
            </div>
          </template>
          <template #setSubcategories="{ data }">
            <div>
              <span>
                {{ setSubcategories(data.data) }}
              </span>
            </div>
          </template>
        </DxDataGrid>
      </VersatileFilter>
    </div>

    <v-dialog v-model="showModel" width="60%">
      <v-card>
        <AccountingItemForm :win="win" v-on:sendSelection="reaciveSelection" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="showfilterdata" max-width="60%">
      <v-card class="pa-4">
        <v-card-title>
          <span class="headline">Filtros</span>
        </v-card-title>
        <v-card-text>
          <v-select
            outlined
            dense
            label="Almacen"
            v-model="filters_selected.warehouse"
            item-text="name"
            item-value="value"
            attach
            :items="filterParams.warehouse"
          ></v-select>
          <v-autocomplete
            outlined
            dense
            label="Familia"
            v-model="filters_selected.family"
            :items="families"
            item-text="name"
            attach
            item-value="id"
          ></v-autocomplete>
          <v-autocomplete
            outlined
            dense
            label="Categoría"
            v-model="filters_selected.category"
            :items="filteredCategories"
            item-text="name"
            attach
            item-value="id"
            :disabled="!filters_selected.family"
          ></v-autocomplete>
          <v-autocomplete
            outlined
            dense
            label="Subcategoría"
            v-model="filters_selected.subcategory"
            :items="filteredSubcategories"
            attach
            item-text="name"
            item-value="id"
            :disabled="!filters_selected.category"
          ></v-autocomplete>
          <div style="display: flex; justify-content: space-between">
            <v-btn
              color="warning"
              dark
              @click="
                showfilterdata = !showfilterdata;
                cleanFilters();
              "
              >Limpiar filtros</v-btn
            >
            <v-btn
              color="primary"
              dark
              @click="
                showfilterdata = !showfilterdata;
                filterValues();
              "
              >Aplicar</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </div>
</template>

<script>
import AccountingItemForm from "./AccountingItemForm.vue";
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxExport,
  DxEditing,
} from "devextreme-vue/data-grid";
import { mapGetters, mapState } from "vuex";
import VersatileFilter from "@/components/VersatileFilter";
import AccountingAssign from "./AccountingAssign.vue";
import alerts from "@/components/Alerts.vue";
import { printToExcel } from "@/helpers/printToexcel";
import Block from "@/components/Block.vue";

export default {
  name: "AllocationProducts",
  components: {
    Block,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxExport,
    VersatileFilter,
    AccountingItemForm,
    DxEditing,
    AccountingAssign,
    alerts,
  },
  props: ["win", "datafilter"],
  data() {
    return {
      tab: null,
      panel: 0,
      form: {},
      showFilter: false,
      showModel: false,
      products: [],
      showProduct: false,
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      search: {},
      AllData: [],
      showfilterdata: false,
      filterWarehouses: {},
      assignOne: '',
      assignedValue: false,
      Reloadfilter: 0,
      filterParams: {
        warehouse: [{ name: "Todos", value: "Todos" }],
        family: [{ name: "Todos", value: "Todos" }],
      },
      warehouses: {},
      families: {},
      categories: {},
      subcategories: {},
      filters_selected: {
        warehouse: "Todos",
        family: null,
        category: null,
        subcategory: null,
      },
      providers: [],
      showProviders: false,
      query: {
        filter: null,
      },
      loading: false,
      changueAll: "inventory",
      accountList: [],
      columsConfig: [
        {
          name: "ID_Item",
          title: "ID Producto",
          value: "",
          type: "text",
        },
        {
          name: "ItemType",
          title: "Tipo de Producto",
          value: "",
          type: "text",
          input: "Escriba el tipo de producto",
        },
        {
          name: "ItemName",
          title: "Nombre del Producto",
          value: "",
          type: "text",
        },
        {
          name: "stock",
          title: "Disponible",
          value: "",
          type: "text",
        },
        {
          name: "WarehouseName_TypesLike_C",
          title: "Almacén",
          value: "",
          type: "select",
          options: [],
        },
        {
          name: "family",
          title: "Familia",
          value: "",
          type: "select",
          options: [],
        },
        {
          name: "category",
          title: "Categoría",
          value: "",
          type: "select",
          options: [],
        },
        {
          name: "subcategory",
          title: "Subcategoría",
          value: "",
          type: "select",
          options: [],
        },
      ],
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
     
      editableFieldsList: [
        "inventory_account_code",
        "sales_account_code",
        "taxed_sales_account_code"
      ],
      newFieldsPair: {
        "inventory_account_code": 'newId_account',
        "sales_account_code": 'newId_sales_account',
        "taxed_sales_account_code": "newId_taxed_sales_account"
      }
    }
  },
  computed: {
    ...mapGetters(["reloadData"]),
    filteredCategories() {
      return this.filters_selected.family
        ? this.categories.filter(
            (category) => category.id_family == this.filters_selected.family
          )
        : [];
    },
    filteredSubcategories() {
      return this.filters_selected.category
        ? this.subcategories.filter(
            (subcategory) =>
              subcategory.id_cat == this.filters_selected.category
          )
        : [];
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  watch: {
    reloadData: {
      immediate: true,
      handler: "reloadProducts2",
    },
  },
  mounted() {
    this.changueAll = this.$store.getters["rolesUser/getArrayCustomRole"]([
      184, 183,
    ])
      ? "inventory"
      : "sales";
    this.$store.dispatch("rolesUser/getAllRoles");
    this.search.business =
      JSON.parse(localStorage.getItem("user")).businessid || "";
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.search.filter = true;
    this.assignParams();

    this.getWarehouse();
    this.getAllProducts();
  },

  methods: {
    assignParams() {
      if (this.datafilter) {
        this.families = this.datafilter.Families;
        this.categories = this.datafilter.Categories;
        this.subcategories = this.datafilter.SubCategories;

        this.columsConfig[5].options = this.datafilter.Families;
        this.columsConfig[6].options = this.datafilter.Categories;
        this.columsConfig[7].options = this.datafilter.SubCategories;
        this.Reloadfilter += 1;
      }
    },
    validateData(e) {
      for (const field of this.editableFieldsList) {
        if (e.newData[field]) {
          const found = this.accountList.find(
            (current) => current.account_code === e.newData[field]
          );
          if (found) {
            e.newData[this.newFieldsPair[field]] = found.id_account;
          } else {
            e.cancel = true;
            e.newData[field] = "";
            this.showAlert(
              "warning",
              "Error",
              "La cuenta ingresada no existe o no es candidata para la asignación"
            );
          }
          break;
        }
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    assignAllAccounts() {
      for (const index in this.products) {
        const found = this.accountList.find(
          (current) => current.id_account === this.form.id_account
        );        
        if (this.changueAll == "inventory" && found) {
          this.products[index].newId_account = this.form.id_account;
          this.products[index].inventory_account_code = found.account_code;
        } else if (this.changueAll == "sales" && found) {
          this.products[index].newId_sales_account = this.form.id_account;
          this.products[index].sales_account_code = found.account_code;
        }
        else if(this.changueAll == 'taxed_sales' && found){
          this.products[index].newId_taxed_sales_account = this.form.id_account;
          this.products[index].taxed_sales_account_code = found.account_code;
        }
      }
      this.$refs.accountProducts.instance.refresh();
      this.assignedValue = true;
      const texto = {
        inventory: 'Inventarios',
        sales: "Costo de Venta",
        taxed_sales: "Ventas"
      }
      this.showAlert("success", "Exito", `Se ha actualizado la tabla asignando la cuenta seleccionada a los productos. Revise la columna "Cuenta de ${ texto[ this.changueAll ] }" para verificar y haga click en el boton verde para guardar`);
    },
    saveProductsAllocation() {
      try {
        if(!this.assignedValue) {
          this.showAlert("warning", "Advertencia", "Antes de guardar la información de asignar la cuenta seleccionada a los productos.");
          return;
        }
        this.loading = true;
        this.$refs.accountProducts.instance.refresh();
        this.$API.branchaccountcatalog
          .assignAccountsProducts([...this.products])
          .then((response) => {
            this.showAlert("success", "Exito", response.msg);
            this.loading = false;
            this.getAllProducts();
          })
          .catch((error) => {
            this.showAlert("error", "Error", error.message);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {
      this.alert.show = false;
    },
    getAllAccounts(dataToFormat) {
      this.$API.branchaccountcatalog
        .getAssignableAccounts()
        .then((response) => {
          this.accountList = response.data;
          this.matchAccounts(dataToFormat);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    matchAccounts(dataToFormat){
      for(const index in dataToFormat) {
        const { id_sales_account, id_inventory_account, id_taxed_sales_account } = dataToFormat[index];

        const found = this.accountList.find( current => current.id_account === id_inventory_account );
        const found2 = this.accountList.find( current => current.id_account === id_sales_account );
        const found3 = this.accountList.find( current => current.id_account === id_taxed_sales_account );

        if(found){
          dataToFormat[index].inventory_account_code = found.account_code;
        }
        if (found2) {
          dataToFormat[index].sales_account_code = found2.account_code;
        }
        if(found3){
          dataToFormat[index].taxed_sales_account_code = found3.account_code;
        }
      }

      this.$refs.accountProducts.instance.saveEditData();
    },
    onEditingStart(e) {
      if (!this.editableFieldsList.includes(e.column.dataField)) {
        e.cancel = true;
      }
    },
    reaciveSelection(data) {
      this.showModel = false;
      if( this.assignOne === 'inventory' ){
        this.showProduct.data.newId_account = data.id_account;
        this.showProduct.data.inventory_account_code = data.account_code;
        this.$refs.accountProducts.instance.saveEditData();
      }
      else if( this.assignOne === 'sales' ) {
        this.showProduct.data.newId_sales_account = data.id_account;
        this.showProduct.data.sales_account_code = data.account_code;
        this.$refs.accountProducts.instance.saveEditData();
      } else if( this.assignOne === 'taxed_sales' ) {
        this.showProduct.data.newId_taxed_sales_account = data.id_account;
        this.showProduct.data.taxed_sales_account_code = data.account_code;
        this.$refs.accountProducts.instance.saveEditData();
      }
    },
    onExporting(e) {
      printToExcel(e, "Productos");
    },
    filterValues() {
      this.products = this.AllData;
      if (this.filters_selected.warehouse != "Todos") {
        this.products = this.products.filter(
          (product) => product.Warehouse == this.filters_selected.warehouse
        );
      }
      if (this.filters_selected.family) {
        this.products = this.products.filter(
          (product) => product.family == this.filters_selected.family
        );
      }
      if (this.filters_selected.category) {
        this.products = this.products.filter(
          (product) => product.category == this.filters_selected.category
        );
      }
    },
    cleanFilters() {
      this.filters_selected.warehouse = "Todos";
      this.filters_selected.family = null;
      this.filters_selected.category = null;
      this.filters_selected.subcategory = null;
      this.products = this.AllData;
    },
    reload() {
      this.getAllProducts();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
      }, 300);
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then((response) => {
        this.families = response;
        this.families.forEach((family) => {
          this.columsConfig[5].options.push({
            name: family.name,
            value: family.id,
          });
        });
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategories().then((response) => {
        this.categories = response;
        this.categories.forEach((category) => {
          this.columsConfig[6].options.push({
            name: category.name,
            value: category.id,
          });
        });
      });
    },
    getSubs() {
      this.$API.itemtypes.getSubs().then((response) => {
        this.subcategories = response;
        this.subcategories.forEach((subcategory) => {
          this.columsConfig[7].options.push({
            name: subcategory.name,
            value: subcategory.id,
          });
        });
      });
    },
    setFamilies(data) {
      let name = "";
      this.families.forEach((family) => {
        if (family.id == data.family) {
          name = family.name;
        }
      });
      return name;
    },
    setCategories(data) {
      let name = "";
      this.categories.forEach((category) => {
        if (category.id == data.category) {
          name = category.name;
        }
      });
      return name;
    },
    setSubcategories(data) {
      let name = "";
      this.subcategories.forEach((subcategory) => {
        if (subcategory.id == data.subcategory) {
          name = subcategory.name;
        }
      });
      return name;
    },
    getWarehouse() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.filterWarehouses.branch = idbranch;
      this.filterWarehouses.business = idbusiness;
      this.$API.warehouse
        .getWarehouses(this.filterWarehouses)
        .then((response) => {
          this.warehouses = response;
          this.warehouses.forEach((warehouse) => {
            this.columsConfig[4].options.push({
              name: warehouse.Warehouse,
              value: warehouse.Warehouse,
            });
          });
          this.Reloadfilter++;
        });
    },
    getAllProducts() {
      this.$API.products
        .getProductsHistory(this.search)
        .then(async (response) => {
          await this.getAllAccounts(response);          
          this.products = [...response];
          this.AllData = [...response];

          this.AllData.forEach((element) => {
            if (
              !this.filterParams.warehouse.some(
                (w) => w.value === element.Warehouse
              )
            ) {
              this.warehouses.forEach((warehouse) => {
                if (warehouse.ID_Warehouse == element.Warehouse) {
                  this.filterParams.warehouse.push({
                    name: warehouse.Warehouse,
                    value: warehouse.ID_Warehouse,
                  });
                }
              });
            }
          });
        });
    },
    reloadProducts(payload) {
      if (payload) {
        this.showProduct = false;
        this.panel = 0;
      }
      this.getAllProducts();
    },
    async reloadProducts2() {
      if (this.reloadData == "products") {
        await this.getAllProducts();
        this.$store.dispatch("reloadData", null);
      }
    },

    showProvidersOfProduct(data) {
      this.providers = [];
      this.$API.providers
        .getCheckedProvidersOfProduct({ iditem: data.ID_Item })
        .then((response) => {
          this.providers = response;
          this.showProviders = true;
        });
    },
    async filterHandler(response) {
      await this.getAllAccounts(response);
      this.products = [...response];
      this.AllData = [...response];

      this.AllData.forEach((element) => {
        if (
          !this.filterParams.warehouse.some(
            (w) => w.value === element.Warehouse
          )
        ) {
          this.warehouses.forEach((warehouse) => {
            if (warehouse.ID_Warehouse == element.Warehouse) {
              this.filterParams.warehouse.push({
                name: warehouse.Warehouse,
                value: warehouse.ID_Warehouse,
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}

.justified-column {
  width: 100%;
  border: 1px solid #ccc;
  /* Solo para visualizar el contenedor */
  padding: 10px;
  /* Espaciado interno */
  word-wrap: break-word;
  /* Permite el salto de palabras */
}

.component-v-card {
  border-radius: 15px;
}
</style>
